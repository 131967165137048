<template>
  <v-row justify="center">
    <v-col align="center" cols="12" md="10" class="my-0">
      <br />
      <p class="text-h6" align="center" style="font-family: 'Handlee', cursive">
        Aviso
        <v-icon color="orange" size="x-small" class="pb-1"> mdi-alert-outline </v-icon>
      </p>
      <p class="text-body mt-1" align="center">
        NENHUMA INFORMAÇÃO PESSOAL É COLETADA DOS USUÁRIOS.
      </p>
      <br />
    </v-col>
  </v-row>
</template>
