<template>
  <!-- Bloco de anúncio -->
  <ins
    class="adsbygoogle"
    style="display: block"
    data-ad-client="ca-pub-7364145551999763"
    data-ad-slot="8827720583"
    data-ad-format="auto"
    data-full-width-responsive="true"
  ></ins>
</template>

<script>
export default {
  name: "GoogleAdDisplay",
  mounted() {
    // Verificar se o objeto adsbygoogle existe e executar push
    if (window.adsbygoogle) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  },
};
</script>
